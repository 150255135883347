<template>
  <div> 
    <div>
      <section
        class="loginformSection"     
      >
       
      <div>  
      <div class="card card-container" v-if="!item">      
      <form name="form" @submit.prevent="handleforgotpassword">
        <div v-if="!successful">         
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="user.email"
              v-validate="'email|max:50'"
              type="email"
              class="form-control"
              name="email"
            />
            <div
              v-if="submitted && errors.has('email')"
              class="alert-danger"
            >{{errors.first('email')}}</div>
          </div>

           <div class="form-group">
            <label>OR</label>
            </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              v-model="user.phone"
              v-validate="'min:10|max:10'"
              type="phone"
              class="form-control"
              name="phone"
            />
            <div
              v-if="submitted && errors.has('phone')"
              class="alert-danger"
            >{{errors.first('phone')}}</div>
          </div>         
          <div class="form-group">
            <button class="btn btn-primary btn-block">Send</button>
          </div>
        </div>
      </form>        
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'">{{message}}
        </div>
    </div>
    <div v-else-if="item">
       <div class="card card-container">      
      <form name="form" @submit.prevent="handleforgotpasswordotp">
        <div v-if="!successful">        
           <div class="form-group">
            <label for="otp">OTP</label>
            <input
              v-model="user.otp"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="otp"
            />
            <div
              v-if="submitted && errors.has('otp')"
              class="alert-danger"
            >{{errors.first('otp')}}</div>
          </div>     
           <div class="form-group">
            <label for="password"> New Password</label>
            <input
              v-model="user.Newpassword"
              v-validate="'required|min:6|max:40'"
              type="password"
              class="form-control"
              name="Newpassword"
              ref="password"
            />
            <div
              v-if="submitted && errors.has('Newpassword')"
              class="alert-danger"
            >{{errors.first('Newpassword')}}</div>
          </div>
           <div class="form-group">
            <label for="password">Re-enter Password</label>
            <input
              v-model="user.reenterpassword"
              v-validate="'required|min:6|max:40|confirmed:password'"
              type="password"
              class="form-control"
              name="reenterpassword"
               data-vv-as="password"
            />
            <div
              v-if="submitted && errors.has('reenterpassword')"
              class="alert-danger"
            >{{errors.first('reenterpassword')}}</div>
          </div>      
          <div class="form-group">
            <button class="btn btn-primary btn-block">Send</button>
          </div>
        </div>
      </form>        
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'">{{message}}
        </div>
    </div>

    </div>
    </div> 
      </section>
    </div>
  </div>
</template>
<script>

import User from '../models/user';
import userService from '../services/user.service';

export default {
  name: 'Login',
    data() {
    return {
      user: new User('', '', ''),
      submitted: false,
      successful: false,
      message: '',
      item:''
    };
  },

   methods: {
    handleforgotpassword() {  
    this.message = '';  
      this.submitted = true;     
      this.$validator.validate().then(isValid => {
        if (isValid) {   
          if( this.user.phone || this.user.email){
            //let cusid =this.$store.state.auth.user[0].customerId;             
            userService.forgotpassword(this.user).then(
            data => {  
              this.item = data.data.Data[0] 
               console.log(data.data.Data[0].otp);        
        
             
            },
            error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );


          }
          else
          {
               this.message = " Phone or Email Field is required"
          }
        }
      });
    
    },
    handleforgotpasswordotp() {  
    this.message = '';  
      this.submitted = true;     
      this.$validator.validate().then(isValid => {
        if (isValid) {  
          
            userService.forgotpasswordotp(this.user,this.item).then(
            data => {  
                 this.message = data.data.Message;
            this.successful = true;
            },
            error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );
        }
      });
    
    },
  }
};

</script>
<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
